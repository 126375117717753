@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "~react-circular-progressbar/dist/styles.css";


@import "styles/fonts.scss";
@import "styles/variables.scss";
@import "styles/layout";
@import "styles/pages";

html body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $global;
  font-weight: 300;
}

a {
  transition: all ease-in-out 0.3s;

  &:hover {
    text-decoration: none;
  }
}

button {
  &:focus {
    outline: none;
  }

  &.btn-link {
    border: none;
    background: transparent;
    font-size: 12px;
    text-decoration: none !important;
  }

  &.MuiButton-contained {
    color: $white;
    background: $grey;
    text-transform: capitalize;
    font-size: 12px;
    padding: 10px 25px;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }

    &:hover {
      background: darken($grey, 25%);
    }
  }

  &.MuiButton-containedPrimary {
    color: $white;
    background: $cornflower;
    text-transform: capitalize;
    font-size: 12px;
    padding: 10px 25px;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }

    &:hover {
      background: darken($cornflower, 25%);
    }

    &.round {
      border-radius: 100px;
    }
  }

  &.MuiButton-containedSecondary {
    background: $iris;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }

    &.reject {
      background: $warm-pink;
    }
  }

  &.full {
    width: 100%;
  }

  &.btn-input {
    padding: 0;
    font-size: 12px;
  }
}

img {
  max-width: 100%;

  &.loading {
    width: 100px;
  }

  &.user-photo {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
  }

  &.img-user {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }

  &.vessel-photo {
    width: 360px;
  }
}

.avatar,
.picture {
  input {
    display: none;
  }
}

.card-comments {
  display: flex;
  border-bottom: 1px solid $grey;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  &:hover {
    .btn-del {
      visibility: visible;
      opacity: 1;
    }
  }

  .img-comment {
    margin-right: 15px;

    img {
      min-width: 60px;
    }
  }

  small {
    color: $cornflower;
    font-size: 11px;
  }

  .btn-del {
    transition: 0.3s ease-in-out all;
    border: none;
    background: transparent;
    color: $warm-pink;
    position: absolute;
    right: 0;
    top: 10px;
    visibility: hidden;
    opacity: 0;
  }

  h5 {
    font-weight: 700;
    font-size: 18px;
  }

  p {
    font-size: 13px;
  }
}

.MuiTypography-root.MuiTypography-h6 {
  font-family: $global;
  font-weight: 700;
}

.MuiFormControlLabel-root {
  .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    color: $black;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $cornflower;
  }

  .MuiCheckbox-root {
    color: $cornflower;
    padding: 0;
    margin-right: 5px;
  }
}

.MuiFormControl-root {
  label {
    font-weight: 300;
    font-size: 14px;
    color: $grey;
    transform: translate(14px, 18px) scale(1);
    font-family: $global;

    &.Mui-focused {
      color: $blue-grey;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $grey;
  }

  &.search-field {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    .MuiOutlinedInput-inputAdornedStart {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    fieldset {
      border-radius: 100px;
    }

    .MuiInputBase-input {
      padding: 14px;
      font-size: 14px;
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }

    .MuiIconButton-root {
      border-radius: 100%;
      background: $pale-grey;

      i {
        font-size: 14px;
        color: $cornflower;
      }
    }
  }

  .MuiInput-underline {
    &:after,
    &:before {
      border-bottom: 2px solid $grey;
    }
  }

  .MuiInputBase-input {
    font-size: 14px;
    padding: 14px;
    font-family: $global;

    .colored & {
      background: $white;
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }
}

.MuiList-root .MuiMenuItem-root {
  font-family: $global;
  font-size: 14px;
}

.text-danger {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.dashboard-container{
  margin-left: 200px;
  padding: 20px;
}

.card-cash {
  box-shadow: 0 1px 5px 0 rgba(113, 113, 113, 0.29);
  border-radius: 0;
  cursor: pointer;
  border: none;

  &.active {
    box-shadow: 0 0 17px 7px rgba(66,48,74,0.8);
    -webkit-box-shadow: 0 0 17px 7px rgba(66,48,74,0.8);
    -moz-box-shadow: 0 0 17px 7px rgba(66,48,74,0.8);
  }

  .card-header {
    padding: 0;
    border: none;
    background: transparent;
  }

  .card-body {
    h3 {
      margin-bottom: 0;
      font-weight: 700;
    }
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $white-three;
  border-radius: 10px;
  outline: 1px solid $white-three;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}