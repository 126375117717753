header {
  &.header-nav {
    z-index: 100;
    position: relative;
    background: $pale-grey;

    @include media-breakpoint-up(xl) {
      position: fixed;
      width: 100%;
      background: white;
      z-index: 1001;
    }

    .navbar {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    button {
      &.icon-head {
        border: none;
        background: transparent;
        margin: 0 20px;
        color: #33297c;

        svg {
          font-size: 35px;
        }

      }

    }


    .nav-item {
      &.name {
        padding: 3px 15px;
        color: $blue-grey;
        font-size: 16px;
        font-weight: 600;
        margin-top: 15px;
      }

      &.profile-picture {
        margin-left: auto;
        .user-photo {
          width: 50px;
          height: 50px;
          margin-top: 20px;
        }

        .content {
          display: inline-grid;
          //max-width: 175px;

          h3 {
            font-weight: 700;
            font-size: 16px;
            color: $dark-slate-blue;
            margin-bottom: 0;
          }

          span {
            font-size: 13px;
            color: $dark-slate-blue;
          }
        }
      }
    }
    .profile-picture {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;

    }

    .MuiButton-containedPrimary {
      color: $white;
      background: $cornflower;
      border-radius: 100px;
      text-transform: capitalize;
      font-size: 14px;
      padding: 8px 18px;

      svg {
        margin-right: 5px;
      }
    }

    p {
      font-weight: 600;
      color: $blue-grey;
    }
  }
}

aside {
  &.side-nav {
    transition: 0.5s ease-in-out all;
    left: -570px;
    position: fixed;
    background: $white;
    bottom: 0;
    top: 95px;
    width: 80%;
    z-index: 1000;
    overflow-y: auto;
    padding-top: 15px;
    padding-bottom: 30px;

    @include media-breakpoint-up(md) {
      width: 245px;
      top: 95px;
    }

    @include media-breakpoint-up(lg) {
      width: 280px;
      box-shadow: none;
      padding-top: 40px;
    }

    &.show {
      left: 0;

    }
    .MuiListItemIcon-root {
      min-width: 32px;
    }

    .MuiListItem-root {
      padding: 3px 15px;

      @include media-breakpoint-up(lg) {
        padding: 8px 20px;
      }

      .MuiListItemText-root {
        span {
          font-size: 14px;
          font-weight: 700;
          color: $blue-grey;
          font-family: $global;
        }
      }
    }

    .profile-picture {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;

      @include media-breakpoint-up(lg) {
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    header {
      @include media-breakpoint-up(lg) {
        padding-top: 30px;
      }
    }

    .nav-item {
      &.name {
        padding: 3px 15px;
        color: $blue-grey;
        font-size: 16px;
        font-weight: 600;
        margin-top: 15px;
      }

      &.profile-picture {
        .user-photo {
          width: 50px;
          height: 50px;
          margin-top: 20px;
        }

        .content {
          display: inline-grid;
          width: 135px;

          h3 {
            font-weight: 700;
            font-size: 16px;
            color: $dark-slate-blue;
            margin-bottom: 0;
          }

          span {
            font-size: 13px;
            color: $dark-slate-blue;
          }
        }
      }

      button {
        font-weight: 600;
        color: $blue-grey;
        display: block;
        text-align: left;
        width: 100%;
        padding: 3px 15px;
        text-decoration: none;
        margin-top: 30px;
        background: transparent;
        border: none;

        @include media-breakpoint-up(lg) {
          padding: 3px 25px;
          margin-bottom: 10px;
          font-size: 15px;
        }

        i {
          margin-right: 15px;
          min-width: 20px;
        }
      }

      &.active {
        a {
          background: $white;
          color: $blue-grey;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }

    hr {
      border-top: 1px solid $white-three;
      margin: 10px 15px;

      @include media-breakpoint-up(lg) {
        margin: 10px 25px;
      }
    }
  }
}

main {
  &.login {
    background: $dark-slate-blue;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    color: $white;

    &::before {
      content: '';
      background-image: url("../images/bg-login.png");
      background-size: 100%;
      background-position: center bottom;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 300px;
      z-index: 0;
    }

    section {
      width: 100%;
      position: relative;
      z-index: 2;

      h1 {
        font-size: 24px;

        @include media-breakpoint-up(md) {
          font-size: 30px;
        }
      }

      p {
        font-size: 14px;
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
      }

      input {
        color: $white;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        background-color: rgba(255, 255, 255, 0.11);
      }

      .MuiIconButton-root {
        color: $white;
      }
      .PrivateNotchedOutline-root-58 {
        border-radius: 50px;
      }

      .btn {
        color: $white;
        background: $dark-indigo;
        border-radius: 50px;
        padding: 20px;
        width: 100%;
        font-size: 16px;

        &:hover {
          background: $grey;
          color: $dark-indigo;
        }
      }

      form {
        max-width: 400px;
        margin: auto;
      }
    }
  }

  &.dashboard {
    background: $pale-grey;
    min-height: 100vh;

    @include media-breakpoint-up(xl) {
      padding-top: 90px;
    }


    .main-content {
      @include media-breakpoint-up(xl) {
        padding-top: 30px;
      }


      .page-title {
        font-size: 22px;
        font-weight: 700;
        color: $black;

        @include media-breakpoint-up(lg) {
          font-size: 26px;
        }
      }

      .breadcrumb {
        background: transparent;
        padding: 0;

        .breadcrumb-item {
          font-size: 12px;
          color: $cornflower;
          font-weight: 500;

          a {
            color: $black;
          }
        }
      }

      .table-wrapper {
        background: $white;
        padding: 20px;
        border-radius: 10px;
        overflow-x: auto;

        .table-heading {
          font-weight: 900;
          font-size: 20px;
        }
        @include media-breakpoint-up(xl) {
          padding: 30px;
        }

        &.detail {
          @include media-breakpoint-up(xl) {
            padding: 20px;
          }

          .number {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 5px;

            @include media-breakpoint-up(md) {
              font-size: 26px;
            }
          }

          .label-grey {
            margin-bottom: 0;
            color: $warm-grey;
            font-size: 14px;
          }

          .info {
            font-size: 13px;
            display: block;

            &.text-blue {
              color: $cornflower;
            }

            &.text-green {
              color: $viridian;
            }
          }

          .info-list {
            border-bottom: solid 1px #eff0f4;
            padding-top: 15px;
            padding-bottom: 15px;

            @include media-breakpoint-up(md) {
              border-bottom: none;
              border-right: solid 1px #eff0f4;

              &:last-child {
                border-right: none;
              }
            }
          }

          &.table-exp {
            .form-group {
              margin-bottom: 10px;
            }

            .row {
              margin-left: -5px;
              margin-right: -5px;
            }

            [class*="col-"] {
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }
      }

      .card-cancel {
        background: $very-light-purple;
        border-radius: 10px;
        padding: 30px 15px 0 15px;
        text-align: center;

        h3 {
          color: $warm-pink;
          font-weight: 700;
          font-size: 16px;
        }

        p {
          font-size: 12px;
          color: $dark-slate-blue;
        }

        img {
          width: 150px;
          margin-top: 20px;
        }
      }

      .card-edit {
        background: $perrywinkle;
        border-radius: 10px;
        padding: 20px 15px 0 15px;
        color: $white;

        p {
          font-size: 14px;
        }
      }
    }

    .dashbboard-container {
      @include media-breakpoint-up(xl) {
        //max-width: calc(100% - 280px);
      }
    }
  }
}
.css-2b097c-container {
  text-transform: uppercase;
}
.form-group {
  label {
    font-weight: 500;
    font-size: 13px;
  }

  .MuiFormControl-root {
    width: 100%;

    &.text-uppercase {
      .MuiInputBase-input {
        text-transform: uppercase;
      }
    }
  }

  .MuiIconButton-root {
    padding: 0;
    font-size: 14px;
  }
}

.icon-sort {
  display: inline-flex;
  height: 10px;

  i {
    color: #a4a3a3;

    &.active {
      color: $black;
    }
  }
}