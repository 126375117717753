.group-title {
  background: $french-blue;
  padding: 10px 15px;
  margin-bottom: 15px;
  margin-top: 15px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  h3 {
    margin-bottom: 0;
    color: $white;
    font-size: 16px;
  }
}

.card-temp {
  background: $pale-grey;
  padding: 5px;
  font-size: 12px;
  border-radius: 3px;

  .list-temp {
    border-bottom: solid 1px #eff0f4;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  button {
    border: none;
    background: transparent;
    color: $warm-pink;
  }
}

.label {
  &-status {
    padding: 5px 10px;
    font-size: 10px;
    color: $white;
    display: block;
    border-radius: 100px;
    min-width: 70px;
    white-space: nowrap;
  }

  &-yellow {
    background: $orange-yellow;
  }

  &-blue {
    background: $cornflower;
  }

  &-green {
    background: $tealish;
  }

  &-purple {
    background: $light-navy-blue;
  }

  &-red {
    background: $warm-pink;
  }
}

.card-abadi {
  border-radius: 10px;
  display: flex;
  background: $color-green;
  margin-bottom: 10px;
  padding: 15px;
  color: $white;
  align-items: center;
  width: 100%;

  @include media-breakpoint-up(md) {
    min-height: 77px;
  }
  @include media-breakpoint-up(xl) {
    padding: 20px;
    min-height: 112px;
  }

  &.yellow {
    background: $orange-yellow;
  }

  &.blue {
    background: $cornflower;
  }

  &.green {
    background: $tealish;
  }

  &.purple {
    background: $light-navy-blue;
  }

  &.red {
    background: $warm-pink;
  }

  .icon-img {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    width: 40px;
    border-radius: 6px;
    margin-right: 15px;

    @include media-breakpoint-up(xl) {
      width: 58px;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.css-2613qy-menu * {
  //position: relative;
  z-index: 100;
}

.detail-wrap {
  @include media-breakpoint-up(md) {
    max-width: 70%;
    width: 70%;
  }
}

.comment-wrap {
  @include media-breakpoint-up(md) {
    max-width: 30%;
    width: 30%;

    &.home {
      max-width: 100%;
      width: 100%;

      p {
        font-size: 14px;

        strong {
          font-weight: 700;
        }

        &.comment {
          font-size: 16px;
        }
      }

      time {
        font-size: 14px;
      }

      .comment-container {
        max-height: 100vh;
      }
    }
  }

  #image {
    display: none;
    border: none;
  }

  .btn-upload {
    padding: 0;
    margin-bottom: 0;
    position: absolute;
    right: 60px;
    top: 18px;
  }

  .photo-image {
    margin-bottom: 15px;
  }

  .comment-container {
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;

    .comment-list {
      background: $white;
      border-radius: 15px;
      padding-left: 15px;
      padding-right: 15px;
      margin: 0 0 20px;
    }
  }
}

.btn-group {
  border-bottom: solid 1px #ebebec;
  width: 100%;

  .btn-tab {
    border: none;
    background: $faded-blue;
    color: $white;
    font-size: 12px;
    border-top-left-radius: 3px;
    border-top-right-radius: 30px;
    padding: 10px 15px;

    &.active {
      background: $french-blue;
    }
  }
}

.title-wrap {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.card-detail {
  border-radius: 10px;
  background: $pale-grey;
  overflow: hidden;
  margin-bottom: 15px;
  padding-bottom: 15px;

  h3 {
    font-size: 14px;
    background: $french-blue;
    color: $white;
    padding: 10px 15px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 10px;
      top: 12px;
      bottom: 12px;
      border-left: 1px solid $white;
    }
  }

  p {
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;

    span {
      color: $blue-grey;
    }
  }

  small {
    font-size: 12px;
    color: $cornflower;
    padding: 3px 10px;
  }

  button.MuiButton-containedPrimary {
    padding: 5px;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    max-width: calc(100% - 20px);

    &.out {
      max-width: 100%;
      margin: 0;
    }
  }

  form {
    padding: 10px;
  }
}

.comment-wrap {
  h3 {
    font-size: 14px;
    font-weight: 600;
    border-bottom: solid 1px #ebebec;
    padding: 10px 0;
  }

  .img-avatar {
    max-width: 75px;
    padding-right: 0;

    img {
      border-radius: 13px;
      height: 60px;
      width: 60px;
      object-fit: cover;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    font-weight: 300;
    color: $brownish-grey;
    margin-bottom: 0;
  }

  time {
    color: $cornflower;
    font-size: 10px;
  }

  form {
    display: flex;
    background: $white;
    border-radius: 29px;
    justify-content: space-between;
    padding: 10px;
    position: relative;

    input {
      width: 75%;
      border: none;
      font-size: 12px;

      &:focus {
        outline: none;
      }
    }

    button {
      background: $pale-grey;
      border: none;
      color: $cornflower;
      border-radius: 18px;
      padding: 5px 8px;
    }
  }
}

.head-form {
  background: $pale-grey-two;
  border-radius: 4px;
  margin: 0;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 15px;
  font-weight: 600;
}

.card-home {
  background: $very-light-purple;
  border-radius: 10px;
  padding: 20px;

  @include media-breakpoint-up(md) {
    padding: 30px 40px;
  }


  blockquote {
    font-weight: 700;
    color: $warm-pink;
    font-size: 20px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }
  }

  p {
    font-size: 14px;
    color: $dark-slate-blue;
    font-weight: 400;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  img {
    @include media-breakpoint-up(xl) {
      margin-top: -85px;
    }
  }
}

a {
  .card-info:hover {
    &.yellow {
      background: darken($orange-yellow, 10) ;
    }

    &.green {
      background: darken($tealish, 10) ;
    }

    &.blue {
      background: darken($cornflower, 10) ;
    }

    &.red {
      background: darken($warm-pink, 10) ;
    }
  }
}

.card-info {
  border-radius: 10px;
  padding: 15px;
  color: $white;
  margin-bottom: 15px;

  @include media-breakpoint-up(xl) {
    padding: 20px;
    margin-bottom: 30px;
  }

  &.yellow {
    background: $orange-yellow;
  }

  &.green {
    background: $tealish;
  }

  &.blue {
    background: $cornflower;
  }

  &.red {
    background: $warm-pink;
  }


  label {
    margin-bottom: 0;
    font-size: 14px;
  }

  .wrap {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 6px;
    padding: 5px 13px 10px 5px;

    @media (min-width: 1600px) {
      min-height: 100px;
      padding: 5px 13px 10px 5px;
    }

    &.square {
      padding: 10px;

      @media (min-width: 1600px) {
        padding: 20px;
      }
    }
  }

  .number {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 5px;

    @include media-breakpoint-up(xl) {
      font-size: 26px;
    }
  }
}

.rbc-calendar {
  min-height: 700px;
}

.card-red {
  background: #fce4e9;
  border-radius: 15px;
  padding: 20px;

  h3 {
    color: #f25767;
    font-weight: 700;
  }
}